<template>
  <collect-container>
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      :inline="true"
      label-position="top"
    >
      <el-form-item prop="contact" label="联系人姓名">
        <el-input v-model="form.contact" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item prop="contact_mobile" label="联系电话">
        <el-input v-model="form.contact_mobile" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item prop="company" label="公司名称">
        <el-input v-model="form.company" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item prop="city" label="所在城市">
        <el-cascader
          v-model="form.city"
          :options="regionList"
          :props="cascaderProps"
          placeholder="所在城市"
          @expand-change="handleItemChange"
        ></el-cascader>
      </el-form-item>
      <el-form-item prop="category_name" label="产品类目">
        <el-select v-model="form.category_name">
          <el-option
            v-for="(product, index) in products"
            :key="index"
            :label="product"
            :value="product"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="has_experience" label="之前是否做过直播电商">
        <el-select v-model="form.has_experience">
          <el-option label="是" :value="1"></el-option>
          <el-option label="否" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="您是从什么渠道了解魔筷的?"
        prop="source"
        class="learn_item"
      >
        <el-select
          v-model="form.source"
          placeholder="请选择"
          @change="where_learn"
        >
          <el-option
            v-for="(item, index) in optionData"
            :key="index"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item prop="learnInfo" v-if="visible" class="learn_item">
        <el-input
          v-model="form.learnInfo"
          placeholder="请输入"
          class="learn"
        ></el-input>
      </el-form-item>
      <el-form-item prop="company_desc" label="公司介绍">
        <el-input
          type="textarea"
          v-model="form.company_desc"
          placeholder="请填写公司介绍（当前主营业务、过往业务、主营类型、数量）"
          class="learn"
        ></el-input>
      </el-form-item>
      <div class="submit-btn" @click="submit">立即提交</div>
    </el-form>
    <collect-success-modal
      :visible="modalVisible"
      @close="closeModal"
    ></collect-success-modal>
  </collect-container>
</template>

<script>
import CollectContainer from '@/components/CollectContainer.vue'
import CollectSuccessModal from '@/components/CollectSuccessModal.vue'

export default {
  components: {
    CollectContainer,
    CollectSuccessModal,
  },
  data() {
    return {
      modalVisible: false,
      form: {
        contact: '',
        contact_mobile: '',
        city: '',
        category_name: '',
        has_experience: '',
        company_desc: '',
        company: '',
        source: '',
        learnInfo: '',
      },
      rules: {
        contact: [
          { required: true, message: '请输入联系人姓名', trigger: 'change' },
        ],
        contact_mobile: [
          {
            required: true,
            message: '请输入联系人手机号码',
            trigger: 'change',
          },
        ],
        company: [
          { required: true, message: '请输入公司名称', trigger: 'change' },
        ],
        city: [
          { required: true, message: '请选择所在城市', trigger: 'change' },
        ],
        category_name: [
          { required: true, message: '请选择类目', trigger: 'change' },
        ],
        has_experience: [
          {
            required: true,
            message: '请选择之前是否做过直播电商',
            trigger: 'change',
          },
        ],
        company_desc: [
          { required: true, message: '请输入公司介绍', trigger: 'change' },
        ],
        source: [{ required: true, message: '请选择渠道', trigger: 'change' }],
        learnInfo: [
          { required: true, message: '请输入渠道', trigger: 'change' },
        ],
      },
      optionData: [
        '朋友介绍',
        '百度搜索',
        '媒体报道',
        '大型活动(行业展会、会议等)',
        '其他',
      ],
      visible: false,
      products: ['食品', '居家百货', '美妆个护', '服饰配件', '其他'],
      cascaderProps: {
        value: 'label',
        children: 'list',
      },
      regionList: [],
    }
  },
  mounted() {
    this.getRegionList().then((res) => {
      if (res.data) {
        this.regionList = res.data.map((item) => ({
          code: item.code,
          label: item.name,
          list: [],
        }))
      }
    })
  },
  methods: {
    closeModal() {
      this.modalVisible = false
    },
    getRegionList(code) {
      return new Promise((resolve, reject) => {
        window.lib.api.get({
          api: 'ec/a/delivery/region/list',
          data: code ? { parent_code: code } : {},
          success: resolve,
          error: reject,
        })
      })
    },
    handleItemChange(data) {
      console.log(data, this.regionList)
      const items = this.regionList.filter((item) => item.label === data[0])
      if (items && items.length) {
        this.getRegionList(items[0].code).then((res) => {
          if (res.data) {
            items[0].list = res.data.map((item) => ({
              code: item.code,
              label: item.name,
            }))
          }
        })
      }
    },
    submit() {
      this.$refs.form.validate((res) => {
        if (res) {
          if (this.visible) {
            this.form.source = this.form.learnInfo
          }
          delete this.form.learnInfo
          console.log('提交的表单数据', this.form)

          let params = {
            ...this.form,
            city: this.form.city.join(''),
          }
          window.lib.api.post({
            api: 'ec/a/supplier/approve/collect',
            data: params,
            success: () => {
              // this.$message.success('提交成功')
              this.$refs.form.resetFields()
              this.modalVisible = true
              this.visible = false
              this.form.city = ''
            },
            error: (err) => {
              this.$message.error(err.msg)
            },
          })
        }
      })
    },
    where_learn(value) {
      this.visible = value == '其他' ? true : false
      this.rules.learnInfo[0].required = this.visible ? true : false
    },
  },
}
</script>

<style lang="less" scoped>
@import '../style/common.less';

.el-form {
  // text-align: center;
  margin: 0 50px;
  .el-form-item {
    width: 300px;
    margin-right: 40px;
    &:nth-child(3n) {
      margin-right: 0;
    }
    &:nth-last-child(2) {
      width: 100%;
    }
    .el-select {
      width: 100%;
    }
    /deep/ .el-input__inner {
      height: 52px;
    }
    .el-textarea {
      /deep/ textarea {
        height: 120px;
      }
    }
    .el-cascader {
      display: block;
    }
  }
  .learn_item {
    .pxToVw(margin-bottom, 20);
    width: 100%;
  }

  .submit-btn {
    margin-top: 80px !important;
  }
}

/deep/ .form-container {
  min-width: 1080px !important;
}
</style>
