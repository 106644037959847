<template>
  <div v-show="visible" class="collect-success-modal">
    <div class="modal-box">
      <div class="close-icon" @click="close"></div>
      <div class="icon-box">
        <img src="https://mktv-in-cdn.mockuai.com/16275264011823302.png" alt="" class="icon">
      </div>
      <div class="title">提交成功</div>
      <div class="text">客户经理将尽快与您联系</div>
      <div class="btn" @click="close">确定</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
  .collect-success-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .8);
    .modal-box {
      position: relative;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 520px;
      height: 400px;
      padding-top: 60px;
      background-color: #fff;
      border-radius: 16px;
      .close-icon {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 16px;
        height: 16px;
        background-image: url(https://mktv-in-cdn.mockuai.com/16270256543822994.png);
        background-size: cover;
        background-repeat: no-repeat;
        cursor: pointer;
      }
      .icon-box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 92px;
        height: 92px;
        margin-bottom: 15px;
        border-radius: 50%;
        background-color: #ff6600;
        .icon {
          width: 59px;
          height: 42px;
        }
      }
      .title {
        margin-bottom: 10px;
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 33px;
      }
      .text {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 25px;
      }
      .btn {
        position: absolute;
        bottom: 60px;
        left: 50%;
        transform: translateX(-50%);
        width: 240px;
        height: 52px;
        line-height: 52px;
        text-align: center;
        border-radius: 30px;
        background-color: #ff6600;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, .8);
        cursor: pointer;
        &:hover {
          color: #fff;
        }
      }
    }
  }
</style>
